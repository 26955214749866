<template>
  <div class="hold-transition">
    <!-- <loading v-if="cargando" /> -->
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="m-0">Programaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Programaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <!-- Tabla de contenido -->
              <section class="col-12">
                <div class="card">
                  <div class="card-header pt-2 pb-2">
                    <div class="row">
                      <div class="col-md-11">
                        <h5>Detalles</h5>
                        <label for="origen" class="col-md-11"
                          ><h5>Sitio</h5></label
                        >
                        <div class="mt-0">
                          <v-select
                            :class="
                              $store.getters.getDarkMode ? 'dark-vselect' : ''
                            "
                            v-model="origen"
                            placeholder="Origenes"
                            label="nombre"
                            class="form-control form-control-sm p-0 col-md-5"
                            v-if="$store.getters.can('tep.solicitudes.lista')"
                            :options="origenes"
                            :filterable="true"
                            @search="getSelectOrigenes"
                          ></v-select>
                        </div>
                        <div class="mt-3 ">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy p-1 col-md-3"
                            @click="getIndex()"
                          >
                            Filtrar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-0">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group" style="text-align: left"></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <FullCalendar :options="calendarOptions" />
                      </div>
                    </div>
                    <div style="display:none">
                      <button
                        type="button"
                        data-toggle="modal"
                        v-if="$store.getters.can('tep.programaciones.detalle')"
                        data-target="#modal-form-detalle-tepprogramacion"
                        ref="detalle_programacion"
                      ></button>
                    </div>
                  </div>
                </div>
              </section>

              <!-- Modal de filtro por fila -->
            </div>
          </div>
        </section>
      </div>
    </div>
    <TepProgramacionDetalle ref="TepProgramacionDetalle" />
  </div>
</template>

<style>
.swal-wide {
  width: 1200px !important;
}
</style>
<script>
import axios from "axios";
import FullCalendar from "@fullcalendar/vue";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listGridPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import esLocale from "@fullcalendar/core/locales/es";
import TepProgramacionDetalle from "./TepProgramacionDetalle";
import vSelect from "vue-select";
// import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "TepProgramacionIndex",
  components: {
    FullCalendar,
    vSelect,
    TepProgramacionDetalle,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [
          listGridPlugin,
          interactionPlugin,
          timeGridPlugin,
          dayGridPlugin,
        ],
        initialView: "dayGridMonth",
        // initialView: "listWeek",
        dateClick: this.handleDateClick,
        locale: esLocale,
        selectable: true,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "listDay,timeGridDay,dayGridMonth",
        },
        events: [],
        eventClick: this.verDet,
        eventColor: "green",
      },
      origen: {},
      origenes: [],
    };
  },
  validations: {},
  methods: {
    // async getOrigenes() {
    //   await axios.get("/api/admin/sitios/lista").then((response) => {
    //     this.origenes = response.data;
    //   });
    // },
    getSelectOrigenes(search) {
      if (search != "") {
        let me = this;
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.origenes = respuesta;
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    async getIndex() {
      axios
        .get("api/tep/programacionTransporte/calendar", {
          params: this.origen,
        })
        .then((response) => {
          this.calendarOptions.events = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    filtrarOrigen() {
      this.origen_id = this.origenes ? this.origenes.id : null;
    },

    async verDet(clickInfo) {
      if (this.$store.getters.can("tep.programaciones.detalle")) {
        this.$refs.TepProgramacionDetalle.llenar_modal(
          clickInfo.event.extendedProps
        );
        this.$refs.detalle_programacion.click();
      }
    },
  },

  mounted() {
    this.getIndex();
    // this.getOrigenes();
  },
};
</script>
